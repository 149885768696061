import React, { useEffect, useState, useContext } from "react";
import stepImage from "../../../assets/images/step1.png";
import ImageButtonComponent from "../../../components/ImageButtonComponent";
import {Link} from "react-router-dom";
import {Images} from "../../../assets/Images";
import {maskCpf, maskPhone} from "../../../Helpers/Helpers";
import moment from "moment";
import {FaArrowLeft} from "react-icons/fa";


const AcceptDoc2 = (props) => {
    const [step, setStep] = useState(0);


    console.log(props)
    return (
        <div className={'p-4 position-relative'}>
            <button type="button" onClick={() => props.setStep(2)}
                    className={`btn d-flex align-items-center gap-1 rounded-pill btn-outline-dark`}>
                <FaArrowLeft size={12}/> <span style={{fontSize:'0.75rem'}}>Voltar</span>
            </button>

            <div>
                <h1 className={'text-center my-3'}>
                    FICHA DE FILIAÇÃO
                </h1>
            </div>
            <div className={`row g-4 my-3`}>
                <div className="col-12 gap-2 d-flex align-items-center">
                    <strong>NOME COMPLETO:</strong>
                    <div className={`border-bottom border-dark flex-grow-1`}><span>{props?.person?.name}</span></div>
                </div>
                <div className="col-12 col-md-6 gap-2 d-flex align-items-center">
                    <strong>NACIONALIDADE:</strong>
                    <div className={`border-bottom border-dark flex-grow-1 text-center`}><span>BRASILEIRO(A)</span></div>
                </div>
                <div className="col-12 col-md-6 gap-2 d-flex align-items-center">
                    <strong>ESTADO CIVIL:</strong>
                    <div className={`border-bottom border-dark flex-grow-1 text-center`}><span>CASADO</span></div>
                </div>
                <div className="col-12 col-md-3 gap-2 d-flex align-items-center">
                    <strong>CPF:</strong>
                    <div className={`border-bottom border-dark flex-grow-1 text-start text-md-center`}><span>{maskCpf(props?.person?.cpfcnpj)}</span></div>
                </div>
                <div className="col-12 col-md-5 gap-2 d-flex align-items-center">
                    <strong>DATA DE NASCIMENTO:</strong>
                    <div className={`border-bottom border-dark flex-grow-1 text-center`}><span>{moment(props?.person?.natural_birthday).format('DD/MM/YYYY')}</span></div>
                </div>
                <div className="col-12 col-md-4 gap-2 d-flex align-items-center">
                    <strong>SEXO:</strong>
                    <div className={`border-bottom border-dark flex-grow-1 text-center`}><span>{props?.person?.natural_gender}</span></div>
                </div>
                <div className="col-12 gap-2 d-flex align-items-center">
                    <strong>ENDEREÇO:</strong>
                    <div className={`border-bottom border-dark flex-grow-1`}><span>{props?.person?.address_street}, {props?.person?.address_number}</span></div>
                </div>
                <div className="col-12 gap-2 d-flex align-items-center">
                    <strong>BAIRRO:</strong>
                    <div className={`border-bottom border-dark flex-grow-1`}><span>{props?.person?.address_district}</span></div>
                </div>
                <div className="col-12 col-md-8 gap-2 d-flex align-items-center">
                    <strong>COMPLEMENTO:</strong>
                    <div className={`border-bottom border-dark flex-grow-1`} style={{minHeight:25}}><span>{props?.person?.address_complement}</span></div>
                </div>
                <div className="col-12 col-md-4 gap-2 d-flex align-items-center">
                    <strong>CEP:</strong>
                    <div className={`border-bottom border-dark flex-grow-1 text-center`} style={{minHeight:25}}><span>{props?.person?.address_cep}</span></div>
                </div>
                <div className="col-12 col-md-9 gap-2 d-flex align-items-center">
                    <strong>CIDADE:</strong>
                    <div className={`border-bottom border-dark flex-grow-1`} style={{minHeight:25}}><span>{props?.person?.address_city_name}</span></div>
                </div>
                <div className="col-12 col-md-3 gap-2 d-flex align-items-center">
                    <strong>UF:</strong>
                    <div className={`border-bottom border-dark flex-grow-1 text-center`} style={{minHeight:25}}><span>{props?.person?.address_state_uf}</span></div>
                </div>
                <div className="col-12 gap-2 d-flex align-items-center">
                    <strong>TELEFONE:</strong>
                    <div className={`border-bottom border-dark flex-grow-1`}><span>{maskPhone(props?.person?.contact_mobile_phone)}</span></div>
                </div>
            </div>


            <div className={`pb-4 pt-2`} dangerouslySetInnerHTML={{ __html: props?.registration_note }} />

            <div className={`row g-4 mb-4`}>
                <div className="col-12 col-md-8 gap-2 d-flex align-items-center">
                    <strong>LOCAL:</strong>
                    <div className={`border-bottom border-dark flex-grow-1`}><span>{props?.person?.address_city_name}/{props?.person?.address_state_uf}</span></div>
                </div>
                <div className="col-12 col-md-4 gap-2 d-flex align-items-center">
                    <strong>DATA:</strong>
                    <div className={`border-bottom border-dark flex-grow-1`}><span>{moment().format('DD/MM/YYYY')}</span></div>
                </div>
            </div>

            <div className={`row justify-content-between pb-4 pt-2 border-top`}>
                <span className="col-6 fw-semibold" style={{fontSize:'0.80rem'}}>INSTITUTO BRASILEIRO DA MELHOR IDADE - IBRAMI</span>
                <span className="col-6 text-end" style={{fontSize:'0.70rem'}}><small>Avenida Afonso Pena, 2362, SL02, Centro, Campo Grande – MS - CNPJ: 12.099.971/0001-10</small></span>
            </div>



            <div className={'row g-3  justify-content-between mt-3'}>
                <button type="button" onClick={() => props.setStep(4)} className={`col-12 col-md-7 col-lg-5 btn-reset p-2 fs-1 btn-ibrami-primary`}>Concordo</button>
            </div>
        </div>
    );
};

export default AcceptDoc2;
