import {FaAngleUp, FaChevronRight, FaFacebookF, FaInstagram, FaWhatsapp} from "react-icons/fa";
import {Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {BsAt} from "react-icons/bs";

export const Footer = () => {
	const scrollTo = (id) => {
		document.getElementById(id).scrollIntoView({behavior: 'smooth'})
	}

	return (
		<footer>
			<div className='border-top  position-relative bg-color ' id='contact'>
				<div className='back-top' onClick={() => scrollTo('home')}>
					<FaAngleUp title='Voltar ao topo' fontSize={28}/>
				</div>
				<div style={{padding: '4rem'}} className='container pe-4 ps-4 pb-4'>
					<div
						className='row justify-content-center justify-content-md-around gap-5 gap-md-0 align-items-center'>
						<Col xs={12} md={4}
							 className='d-flex justify-content-center align-items-center justify-content-center text-center text-md-start'>
							<div>
								<div className='text-primary fw-bolder mb-3 text-white'>
									Sobre
								</div>
								<div>
									<Link
										className='fw-light d-flex align-items-center justify-content-center justify-content-md-start gap-2 text-decoration-none mb-2 text-white social-media-icons'
										to={'termos'}>
										<FaChevronRight size={24}/> <span>Termos de uso</span>
									</Link>
								</div>

								<div>
									<Link
										className='fw-light d-flex align-items-center gap-2 text-decoration-none text-white social-media-icons'
										to={'termos'}>
										<FaChevronRight size={24}/> <span>Política de Privacidade</span>
									</Link>
								</div>
							</div>
						</Col>

						<Col xs={12} md={4}
							 className='d-flex justify-content-center align-items-center justify-content-center text-center text-md-start'>
							<div>
								<div className='text-primary fw-bolder mb-3 text-white'>
									Redes Sociais
								</div>
								<a href="https://www.facebook.com/institutoibrami/"
								   target="_blank"
								   rel='noreferrer'
								   className='d-flex text-decoration-none align-items-center mb-2 gap-2 fw-light text-white social-media-icons'>
									<div>
										<FaFacebookF size={28}/>
									</div>
									<span className=''>Facebook</span>
								</a>
								<a href="https://www.instagram.com/institutoibrami/"
								   target="_blank"
								   rel='noreferrer'
								   className='d-flex text-decoration-none align-items-center  gap-2 fw-light text-white social-media-icons'>
									<div>
										<FaInstagram size={28}/>
									</div>
									<span>Instagram</span>
								</a>
							</div>
						</Col>

						<Col xs={12} md={4}
							 className='d-flex justify-content-center align-items-center justify-content-center text-center text-md-start'>
							<div>
								<div className='text-primary fw-bolder mb-3 text-white'>
									Ajuda
								</div>
								<a href='https://ibrami.org/whatsapp-revisao'
								   target="_blank"
								   rel='noreferrer'
								   className='d-flex text-decoration-none align-items-center justify-content-center justify-content-md-start gap-2 mb-2 fw-light text-white social-media-icons'>
									<div>
										<FaWhatsapp size={28}/>
									</div>
									<span>Fale conosco </span>
								</a>
								<a href={'mailto:contato@ibrami.org'}
								   target="_blank"
								   rel='noreferrer'
								   className='d-flex text-decoration-none align-items-center gap-2 fw-light text-white social-media-icons'>
									<div>
										<BsAt size={28}/>
									</div>
									<span>contato@ibrami.org</span>
								</a>
							</div>
						</Col>
					</div>

					<div className='border-top mt-4'>
						<div className='text-center mt-4 mt-2 text-white'>
							<small>
								<div>
									&copy; 2022 - IBRAMI - Instituto Brasileiro da Melhor Idade - CNPJ
									12.099.971/0001-10
								</div>
								<div>
									Avenida Afonso Pena, 2362, sala 02, Centro, <span className='d-block d-md-inline'>CEP 79.002-074, Campo Grande - MS</span>
								</div>
							</small>

						</div>
					</div>
				</div>
			</div>
        </footer>
	)
}
