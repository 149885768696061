import React from "react";
import {Container} from "react-bootstrap";
import {Images} from "../../../assets/Images";

const TermsOfUse = () => {

    return (
        <Container className='my-5 terms-of-use'>
            <div>
                <img src={Images.ibramiLogo} width={250} alt=""/>
            </div>
            <div className='mt-4'>
                <strong>
                    Instituto Brasileiro da Melhor Idade - IBRAMI
                </strong>
            </div>

            <div className='mt-4 text-center fs-5'>
                <strong>TERMOS DE USO</strong>
            </div>

            <div className='section mt-4'>
                <div>
                    O IBRAMI é uma associação ou entidade de classe, que congrega aposentados, pensionistas e idosos
                    de todo o território nacional, cuja plataforma tecnológica é de propriedade da empresa Instituto
                    Brasileiro da Melhor Idade - IBRAMI (CNPJ: 12.099.971/0001–10) ("INSTITUTO"), tendo por objetivo
                    promover a inclusão digital dos aposentados, pensionistas e população idosa em geral, através de
                    plataformas interativas com conteúdo específico e relevantes.
                </div>
                <div>
                    Os presentes Termos aplicam-se especificamente à plataforma IBRAMI, englobando todos os seus
                    subsistemas.
                </div>
                <div>
                    Estes Termos poderão ser atualizados, a qualquer tempo, pelo IBRAMI, mediante aviso no site e/ou
                    por e-mail, se o Usuário tiver optado por receber comunicações da plataforma.
                </div>
            </div>
            <div className='section mt-4'>
                <div>
                    <strong>1. CONDIÇÕES GERAIS DE ACESSO E UTILIZAÇÃO DO IBRAMI</strong> <br/>
                    <strong className='ms-3'>1.1 Da utilização do IBRAMI</strong>
                    <div className='ms-3'>
                        O acesso e a utilização do IBRAMI pelo Usuário têm, como regra, caráter tanto gratuito quanto
                        oneroso, de acordo com as formas e valores previamente publicados e determinados.
                    </div>
                </div>

                <div  className='ms-3'>
                    <strong>1.2 Registro de Usuário</strong>
                    <div>
                        Para utilização da plataforma e funcionalidades do IBRAMI, será exigida prévia inscrição ou prévio
                        registro do Usuário para acesso. Nestes casos, o acesso à Plataforma IBRAMI somente será liberado
                        após o preenchimento de cadastro pelo Usuário com as informações requeridas.
                    </div>
                    <div>
                        Toda informação fornecida pelo Usuário por meio do IBRAMI deverá ser verdadeira. Assim, o
                        Usuário garante a autenticidade de todos os dados que informar durante o preenchimento dos
                        respectivos formulários, de modo que o Usuário poderá exercer o seu direito de correção de dados
                        incompletos, inexatos ou desatualizados, para manter toda a informação fornecida à plataforma
                        permanentemente atualizada, de maneira que sempre reflita os dados reais do Usuário
                    </div>
                    <div>
                        Em qualquer circunstância, o Usuário será o único responsável por declarações falsas ou inexatas que
                        prestar e que vierem a causar prejuízos ao IBRAMI ou a terceiros
                    </div>
                </div>

                <div className='ms-3'>
                    <strong>
                        1.3 Termos específicos para utilização dos subsistemas
                    </strong>
                    <div>
                        Para cada funcionalidade disponibilizada pelo IBRAMI é disponibilizado ao Usuário, de forma clara e
                        objetiva, um termo de uso específico contendo as regras de utilização, documento este cuja
                        aceitação (opt-in) será exigida do Usuário para que usufrua das funcionalidades, enquanto for de seu
                        interesse
                    </div>
                </div>

                <div className='ms-3'>
                    <strong>
                        1.4 Menores de Idade
                    </strong>
                    <div>
                        O IBRAMI é direcionado a pessoas capazes e suas funcionalidades e soluções não são direcionadas e
                        não devem ser utilizadas por Usuários absolutamente incapazes (menores de 16 anos). De modo a
                        evitar o acesso indevido, o IBRAMI avisa claramente que suas funcionalidades e seu conteúdo é
                        especificamente direcionado a usuários idosos, com idade superior a 60 (sessenta) anos, pelo que
                        será solicitado ao Usuário que indique sua data de nascimento, para fins de verificação da
                        classificação etária.
                    </div>
                    <div>
                        O IBRAMI, no entanto, sob hipótese alguma, se responsabiliza pelo acesso indevido a sua
                        plataforma, decorrente de informações incorretas ou inverídicas fornecidas pelo Usuário ou do
                        desrespeito à classificação etária indicada.
                    </div>
                </div>

                <div className='ms-3'>
                    <strong>
                        1.5 Obrigação de utilizar a plataforma corretamente
                    </strong>
                    <div>
                        Ao utilizar o IBRAMI, o Usuário se compromete a respeitar a legislação brasileira vigente, bem como
                        todo o quanto disposto nos presentes Termos, não devendo produzir, disponibilizar, divulgar ou
                        transmitir qualquer conteúdo que:
                    </div>
                    <div className='d-flex flex-column'>
                       <p>
                           a) Seja contrário a qualquer norma da legislação brasileira, bem como à moral e aos bons costumes
                           geralmente aceitos;
                       </p>
                        <p>
                           b) Incentive a discriminação, o ódio ou a violência contra pessoas ou grupos em razão de orientação
                            sexual, gênero, raça, religião, deficiência, nacionalidade ou por qualquer outro motivo;
                        </p>
                        <p>
                            c) Incorporem, ponham à disposição ou permitam acessar produtos, elementos, mensagens e/ou
                            serviços ilegais, violentos, pornográficos, degradantes ou, em geral, contrários à lei, à moral e aos
                            bons costumes geralmente aceitos ou à ordem pública;
                        </p>
                        <p>
                            d) Sejam falsos, ambíguos, inexatos, exagerados ou extemporâneos, de forma que possam induzir a
                            erro sobre seu objeto ou sobre as intenções ou propósitos do comunicante;
                        </p>
                        <p>
                            e) Sejam protegidos por quaisquer direitos de propriedade intelectual ou industrial pertencentes a
                            terceiros, sem que o Usuário tenha obtido previamente dos seus titulares a autorização necessária
                            para levar a cabo o uso que efetuar ou pretender efetuar;
                        </p>
                        <p>
                            f) Incorporem vírus ou outros elementos físicos ou eletrônicos que possam causar dano ou impedir o
                            normal funcionamento da rede, do sistema ou de equipamentos informáticos (“hardware” e
                            “software”) de terceiros, ou que possam causar dano aos documentos eletrônicos e arquivos
                            armazenados nestes equipamentos informáticos;
                        </p>
                        <p>
                            g) Provoquem, por suas características (tais como forma, extensão etc.) dificuldades no normal
                            funcionamento do Serviço;
                        </p>
                        <div>
                            Em qualquer circunstância, o Usuário é o único responsável pelo uso que fizer do IBRAMI, bem como
                            por qualquer conteúdo por ele inserido.
                        </div>
                    </div>
                </div>

                <div className='ms-3'>
                    <strong>
                        1.6 Utilização Correta do Conteúdo e Propriedade Intelectual
                    </strong>
                    <div>
                        O Usuário se compromete a utilizar o IBRAMI, notadamente os dados e conteúdos de pessoas físicas
                        que foram publicados na rede social do aplicativo, de acordo com todo o ordenamento jurídico, com
                        a moral e os bons costumes geralmente aceitos, com os presentes Termos e com as Condições
                        Particulares de cada Página.
                    </div>
                    <div>
                        Os conteúdos disponibilizados ao Usuário que envolvam dados pessoais, sensíveis ou não, de outros
                        Usuários deverão ser utilizadas com a estrita funcionalidade de interação entre eles, vedado a
                        replicação ou reprodução, podendo o Usuário estar sujeitos às sanções civis e criminais
                        correspondentes, nos termos das Leis 9.610/98, 9.609/98 e 9.279/96.
                    </div>
                    <div>
                        Os demais conteúdos, exceto os dados pessoais disponibilizados através do IBRAMI, são de
                        propriedade do IBRAMI, de seus titulares ou de terceiros que autorizaram sua utilização no IBRAMI, e
                        estão protegidos por leis e tratados internacionais, sendo vedada sua cópia, reprodução, ou qualquer
                        outro tipo de utilização, ficando os infratores sujeitos às sanções civis e criminais correspondentes,
                        nos termos das Leis 9.610/98, 9.609/98 e 9.279/96.
                    </div>
                    <div>
                        O Usuário deverá se abster de obter, ou de tentar obter, os Conteúdos por meios distintos daqueles
                        que, em cada caso, tenham sido colocados à disposição para tais propósitos.
                    </div>
                </div>

                <div className='ms-3'>
                    <strong>
                        1.7 Política Anti-Spamming do IBRAMI
                    </strong>
                    <div>
                        Por meio deste documento o Usuário contratante se obriga a abster-se de:
                    </div>
                    <div className='d-flex flex-column'>
                        <p>
                            a) obter dados com finalidade publicitária e remeter publicidade de qualquer classe com finalidade
                            de venda ou outras de natureza comercial sem a prévia solicitação e consentimento do Usuário;
                        </p>
                        <p>
                            b) remeter a um grupo de pessoas quaisquer mensagens não solicitadas nem consentidas
                            previamente;
                        </p>
                        <p>
                            c) enviar cadeias de mensagens eletrônicas não solicitadas nem previamente consentidas;
                        </p>
                        <p>
                            d) utilizar listas de distribuição a que se pode ter acesso através do IBRAMI para a realização das
                            atividades descritas nos itens acima; e
                        </p>
                        <p>
                            e) colocar à disposição de terceiros, com qualquer finalidade, dados captados a partir do IBRAMI.
                        </p>
                    </div>
                    <div>
                        O Usuário ou terceiros prejudicados pela recepção de mensagens não solicitados, relacionadas ao
                        IBRAMI e dirigidas a pluralidade de pessoas poderão efetuar sua reclamação por meio do e-mail
                        contato@ibrami.org.
                    </div>
                </div>

                <div className='ms-3'>
                    <strong>
                        1.8 Introdução de “hiperlinks” que permitam o acesso ao IBRAMI
                    </strong>
                    <div>
                        O Usuário que se propuser a estabelecer hiperlink entre sua página web ou ainda compartilhar links
                        do IBRAMI (“Hiperlink”) deverá cumprir as seguintes condições:
                    </div>
                    <div className='d-flex flex-column'>
                        <p>
                            a) o “Hiperlink” permitirá única e exclusivamente o acesso à homepage (página de início do IBRAMI) ou à página específica do conteúdo que se queira interligar, mas não poderá reproduzi-la de qualquer forma;
                        </p>
                        <p>
                            b) não declarar nem fazer entender que o IBRAMI teria autorizado o “Hiperlink” ou que teria supervisionado ou assumido, sob qualquer forma, responsabilidade sobre os serviços oferecidos ou colocados à disposição na página web em que for estabelecido o “Hiperlink”;
                        </p>
                        <p>
                            c) com exceção dos sinais que formarem parte do “Hiperlink”, a página web em que se estabelecer o
                            “Hiperlink” não conterá nenhuma marca, nome comercial, logotipo, slogan, look and feel ou
                            quaisquer outros sinais pertencentes ao IBRAMI;
                        </p>
                        <p>
                            d) a página web em que estabelecer o “Hiperlink” não deverá conter informações ou conteúdos
                            ilícitos, contrários à moral, aos bons costumes ou à ordem pública, bem como não conterá conteúdos
                            contrários aos direitos de terceiros.
                        </p>
                        <p>
                             e) não criar um frame sobre o IBRAMI, exceto se houver autorização expressa que possibilite
                             inclusive a interligação via interface de programação de aplicações (“API”);
                        </p>
                        <p>
                             f) não realizar manifestações ou indicações falsas, inexatas ou incorretas sobre o IBRAMI, seus
                             administradores, empregados, ou Plataformas a ele vinculadas
                        </p>
                        <div>
                            A disponibilização de um “Hiperlink” não implica, em qualquer hipótese, a existência de relações
                            entre o IBRAMI e o proprietário da página web que o contiver, nem a aceitação ou aprovação da
                            IBRAMI acerca de seus conteúdos ou serviços.
                        </div>

                    </div>
                </div>
            </div>

            <div className='section mt-4'>
                <strong>2. DURAÇÃO E FINALIZAÇÃO</strong>
                <div>
                    O acesso ao IBRAMI é concedido aos Usuários por prazo indeterminado, podendo, no entanto, ser
                    disponibilizado de maneira temporária, de acordo com as campanhas da associação. Ao IBRAMI, no
                    entanto, é facultado dar por terminado, suspender ou interromper unilateralmente, a qualquer
                    momento e sem necessidade de prévio aviso, o acesso à plataforma, sem que qualquer indenização
                    seja devida ao Usuário.
                </div>
                <div>
                    O INSTITUTO se reserva, ainda, no direito de recusar ou retirar o acesso ao IBRAMI, a qualquer
                    momento e sem necessidade de prévio aviso, por iniciativa própria ou por exigência de um terceiro,
                    àqueles Usuários que descumprirem estes Termos. Nesta hipótese, caso haja créditos, valores
                    pecuniários ou quaisquer outros ativos equivalentes em poder do IBRAMI, ainda que somente dados
                    pessoais sensíveis ou não de acordo com a política de privacidade, estes serão devolvidos aos seus
                    titulares, em até 30 dias úteis.
                </div>
            </div>

            <div className='section mt-4'>
                <strong>3. EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</strong>
                <div>
                    Em decorrência de questões técnicas e operacionais, o IBRAMI não pode garantir a sua
                    disponibilidade, infalibilidade e continuidade de funcionamento, nem garante a utilidade do IBRAMI
                    para a realização de qualquer atividade em concreto. Desse modo, o INSTITUTO não será, em
                    hipótese alguma, responsável por quaisquer danos decorrentes da interrupção de seu acesso, ou por falhas em seu funcionamento, nem se responsabiliza pela defraudação da utilidade que os Usuários
                    possam ter atribuído à plataforma, pela sua falibilidade, nem por qualquer dificuldade de acesso.
                </div>
                <div>
                    O INSTITUTO utiliza as melhores práticas recomendadas de mercado para manter seguros todos os
                    dados inseridos por seus Usuários, no entanto não garante que terceiros não autorizados não farão
                    uso de meios ilícitos para obter indevidamente tais informações.
                </div>
                <div>
                    Assim, o INSTITUTO se exime de responsabilidade pelos danos e prejuízos de toda natureza que
                    possam decorrer do conhecimento que terceiros não autorizados possam ter de quaisquer das
                    informações repassadas, em decorrência de falha exclusivamente atribuível aos Usuários ou a
                    terceiros que fujam a qualquer controle razoável do INSTITUTO.
                </div>
                <div>
                    O INSTITUTO não garante a ausência de vírus, bem como de outros elementos nocivos que possam
                    produzir alterações nos sistemas informáticos dos Usuários (software e hardware) ou nos
                    documentos eletrônicos armazenados no sistema informático, eximindo-se de qualquer
                    responsabilidade pelos danos e prejuízos que possam decorrer de quaisquer elementos nocivos.
                </div>
                <div>
                    O INSTITUTO não verifica ou controla o uso que os Usuários fazem de sua plataforma. Dessa forma, O
                    INSTITUTO se exime de qualquer responsabilidade pelos danos e prejuízos de toda natureza que
                    possam decorrer de sua utilização ou que possam ser devidos à falta de veracidade, vigência,
                    completude e/ou autenticidade do conteúdo lá disponibilizado, bem como da informação que os
                    Usuários proporcionam a outros Usuários sobre si mesmos e, em particular, ainda que não de forma
                    exclusiva, pelos danos e prejuízos de toda a natureza que possam ser devidas à suplantação da
                    personalidade efetuada por um Usuário em qualquer classe de comunicação realizada por meio do
                    IBRAMI.
                </div>
            </div>

            <div className='section mt-4'>
                <strong>4. PROCEDIMENTO EM CASO DE REALIZAÇÃO DE ATIVIDADES DE CARÁTER ILÍCITO</strong>
                <div>
                    Na hipótese de um Usuário ou um terceiro considerar que existem informações, fatos ou
                    circunstâncias que constituem atividade ilícita no IBRAMI, especialmente em caso de violação de
                    direitos de propriedade intelectual ou outros direitos, deverá enviar comunicação ao INSTITUTO
                    contendo os seguintes dados:
                </div>
                <div>
                    <p>
                        a) dados pessoais: nome, endereço, número de telefone e endereço de correio eletrônico do
                        reclamante;
                    </p>
                    <p>
                        b) especificação da suposta atividade ilícita ocorrida no IBRAMI e, em particular, quando se tratar de
                        suposta violação de direitos autorais, indicação precisa e completa dos conteúdos protegidos e
                        supostamente infringidos;
                    </p>
                    <p>
                        c) fatos ou circunstâncias que revelam o caráter ilícito de tal atividade; e
                    </p>
                    <p>
                        d) se for o caso, declaração expressa e clara de que a utilização dos conteúdos foi realizada sem o
                        consentimento do titular dos direitos de propriedade intelectual supostamente infringidos.
                    </p>
                </div>

               <div>
                   Sempre de acordo com a legislação em vigor e com as circunstâncias técnicas de convênios e acordos
                   entre o IBRAMI e parceiros, a partir da ciência, o INSTITUTO poderá excluir conteúdo acusado como
                   ilícito, assim como fornecer informações às autoridades competentes, mediante o competente
                   requerimento, com a finalidade de viabilizar a apuração de condutas ilegais.
               </div>

            </div>

            <div className='section mt-4'>
                <strong>5. DISPOSIÇÕES GERAIS</strong>
                <div>
                    Se qualquer parte destes Termos for considerada inválida ou inexequível, tal trecho deve ser
                    interpretado de forma consistente com a lei aplicável, para refletir, na medida do possível, a intenção
                    original das partes, e as demais disposições permanecerão em pleno vigor e efeito.
                </div>
                <div>
                    A falha do IBRAMI em exigir quaisquer direitos ou disposições dos presentes Termos não constituirá
                    renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.
                </div>
            </div>

            <div className='section mt-4'>
                <strong>6. NOTIFICAÇÕES</strong>
                <div>
                    Todas as notificações e comunicações (doravante, as “Notificações”) por parte dos Usuários ao
                    IBRAMI são consideradas eficazes, para todos os efeitos, quando forem dirigidas por meio do e-mail
                    contato@ibrami.org, e suas respostas serão efetuadas ao e-mail remetente ou através das seguintes
                    formas:
                </div>
                <p>
                    a) envio de carta ao domicílio do Usuário quando este tiver fornecido um endereço válido ao IBRAMI;
                </p>
                <p>
                    b) envio de mensagem por correio eletrônico a qualquer dos endereços fornecidos pelo Usuário;
                </p>
                <p>
                    c) comunicação telefônica ao número fornecido pelo Usuário; e,
                </p>
                <p>
                    d) mediante a inclusão de mensagens em qualquer área da plataforma do IBRAMI;
                </p>
                <div>
                    Neste sentido, todas as Notificações que o IBRAMI realizar serão consideradas válidas quando
                    efetuadas empregando os dados e através dos meios anteriormente destacados. Para estes efeitos, o
                    Usuário declara que todos os dados fornecidos são válidos e corretos, comprometendo-se a
                    comunicar ao IBRAMI todas as mudanças relativas aos dados de notificação.
                </div>

            </div>

            <div className='section mt-4'>
                <strong>7. LEGISLAÇÃO E FORO APLICÁVEIS</strong>
                <div>
                    Esses Termos serão regidos, interpretados e executados de acordo com as leis da República
                    Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou
                    países, sendo competente o Foro da Comarca de Campo Grande (MS), para dirimir qualquer dúvida
                    decorrente deste instrumento.
                </div>
            </div>
            <div className='section mt-4'>
                <strong>Versão: 25 de novembro de 2022.</strong>
            </div>
        </Container>
    )
}

export default TermsOfUse
